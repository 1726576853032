import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogOut from "./components/log-out/LogOut";
import "./app.css";
import Home from "./components/home/Home";
import RefundPolicy from "./components/refund-policy/RefundPolicy";
import CustomerSupport from "./container/CustomerSupport/CustomerSupport";
import TermsOfService from "./container/TermsOfService/TermsOfService";
import PrivacyPolicy from "./container/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./container/AboutUs/AboutUs";
import CorporateSocialResponsibility from "./container/CorporateSocialResponsibility/CorporateSocialResponsibility";

// Lazy loading components
const Register = React.lazy(() => import("./components/register/Register"));
const ProtectedRoute = React.lazy(() => import("./utils/protectedRoute"));
const SharedRoute = React.lazy(() => import("./utils/sharedRoute"));
const PublicView = React.lazy(() => import("./components/dash-board/PublicView"));
const SuccessPage = React.lazy(() => import("./components/money/success"));
const CancelPage = React.lazy(() => import("./components/money/cancel"));

const LoadingMessage = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px'
  }}>
    <h2>Welcome to burnmy.money: Where memories meet liberation‎️‍🔥🪙=🧠💆</h2>
    <p>The app is loading...</p>
  </div>
);

function App() {
  return (
    <section>
      <ToastContainer />
      <LogOut />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/getburn" element={<Suspense fallback={<LoadingMessage />}><ProtectedRoute /></Suspense>} />
          <Route path="/shareburn" element={<Suspense fallback={<LoadingMessage />}><SharedRoute /></Suspense>} />
          <Route path="/register" element={<Suspense fallback={<LoadingMessage />}><Register /></Suspense>} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/support' element={<CustomerSupport />}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/social-responsibility' element={<CorporateSocialResponsibility />} />
          <Route path='/public' element={<Suspense fallback={<LoadingMessage />}><PublicView /></Suspense>} />
          
          {/* Routes for Success and Cancel pages */}
          <Route path='/success' element={<Suspense fallback={<LoadingMessage />}><SuccessPage /></Suspense>} />
          <Route path='/cancel' element={<Suspense fallback={<LoadingMessage />}><CancelPage /></Suspense>} />
        </Routes>
      </BrowserRouter>
    </section>
  );
}

export default App;