import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUserAuthenticated: false,
    userDetails: {},
    receiptId: 1,
    paymentResult: {},
    userBurnDetails: {},
    logUserDetails: {},
    countryDetails: {},
    countryRes: {},
    userId: ''
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers: {
        storeisUserAuthenticated: (state, action) => {
            state.isUserAuthenticated = action.payload;
        },
        storeUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        storeReceiptId: (state, action) => {
            state.receiptId = action.payload;
        },
        storePaymentResult: (state, action) => {
            state.paymentResult = action.payload;
        },
        storeUserBurnDetails: (state, action) => {
            state.userBurnDetails = action.payload;
        },
        storeLogUserDetails: (state, action) => {
            state.logUserDetails = action.payload;
        },
        storeCountryDetails: (state, action) => {
            state.countryDetails = action.payload;
        },
        storeCountryRes: (state, action) => {
            state.countryRes = action.payload;
        },
        storeUserId: (state, action) => {
            state.userId = action.payload;
        },
    }
})

export const { 
    storeisUserAuthenticated,
    storeUserDetails,
    storeReceiptId,
    storePaymentResult,
    storeUserBurnDetails,
    storeLogUserDetails,
    storeCountryDetails,
    storeCountryRes,
    storeUserId
} = userSlice.actions;
export default userSlice.reducer;