import React from 'react';
import { ABOUT_US, ABOUT_US_LABEL, BURN_LABEL, NEGATIVITY_LABEL, TERMSOFSERVICE } from "../../utils/contants";
import BACK from "../../assets/images/backIcon.png";

const TermsOfService = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <section className="flex flex-col relative bg-[#452E26] min-h-screen gap-5 max-w-screen-2xl mx-auto">
                <button type="button" className="ml-1 mr-5 mt-2 fixed z-20" onClick={() => window.history.back()}>
                    <img className="cursor-pointer" width={30} src={BACK} />
                </button>
                <div className="flex flex-col w-[75%] mx-auto items-center ">
                    <span className="text-[5rem] text-[#FFA91A] flex justify-center font-[funkRocker-regular] sm:text-[12rem] sm:h-[35vh] 2xl:h-[15vh]">
                        {BURN_LABEL}
                    </span>
                    <span className="text-[2rem] text-[#FFA91A] flex justify-end h-[10vh] font-[funkRocker-regular] sm:mt-[5vw] sm:w-[80%] sm:p-0 text-nowrap">
                        {NEGATIVITY_LABEL}
                    </span>
                </div>
                <div className="left-0 right-0 top-[200px] bottom-0 flex justify-center p-3">
                    <div className="bg-bgBlack border-8 border-[#D9C3AF] shadow-3xl shadow-[#D9C3AF]/60 rounded-2xl relative h-full text-white font-mono p-5 w-[92%] sm:w-[80%]">
                    <h2 className="font-bold text-[2rem] text-center text-white">{TERMSOFSERVICE.TITLE}</h2>
                        <div className="mx-auto">
                            <div className="p-6">
                                <h2 className="text-lg font-semibold mb-4 text-white">{TERMSOFSERVICE.LIST1.TITLE}</h2>
                                <p className="text-white">{TERMSOFSERVICE.LIST1.DATA}</p>
                            </div>

                            <div className="p-6">
                                <h2 className="text-lg font-semibold mb-4 text-white">{TERMSOFSERVICE.LIST2.TITLE}</h2>
                                <p className="text-white">{TERMSOFSERVICE.LIST2.DATA}</p>
                            </div>

                            <div className="p-6">
                                <h2 className="text-lg font-semibold mb-4 text-white">{TERMSOFSERVICE.LIST3.TITLE}</h2>
                                <p className="text-white">{TERMSOFSERVICE.LIST3.DATA}</p>
                            </div>

                            <div className="p-6">
                                <h2 className="text-lg font-semibold mb-4 text-white">{TERMSOFSERVICE.LIST4.TITLE}</h2>
                                <p className="text-white">{TERMSOFSERVICE.LIST4.DATA}.</p>
                            </div>

                            <div className="p-6">
                                <h2 className="text-lg font-semibold mb-4 text-white">{TERMSOFSERVICE.LIST5.TITLE}</h2>
                                <p className="text-white">{TERMSOFSERVICE.LIST5.DATA}.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    );


};

export default TermsOfService;
