import dotenv from 'dotenv';
dotenv.config(); // Load environment variables

// console.log(process.env.HOST_ENV, "-->ENVIRONMENT");

// if (process.env.HOST_ENV === 'production') {
//     dotenv.config({ path: '.env.production' });
// } else {
//     dotenv.config({ path: '.env.development' });
// }

// console.log(process.env.HOST_ENV, process.env.REACT_APP_HOST_URL, "OPOPPO");

export const WS_URL = process.env.REACT_APP_WS_URL;
export const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
// export const KEY = process.env.REACT_APP_KEY
export const KEY = 'rzp_test_LauAC2zcXNSLhH'

