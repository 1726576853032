 import avatar from "../assets/images/avatar.svg"
 export const INITIAL_CURRENCY = 50
 export const LEADER_BOARD_LIST_LENGTH = 5
 export const STATIC_POINT_LENGTH = 20
 export const COUNT_INCREASE_BY = 1
 export const BORDER_COLORS = ['border-[#FF3821]','border-[#FE8125]','border-[#E051CF]','border-[#A137DF]']
//  export const LEADER_BOARD_DATA =  [
//     {
//         name: 'Mike',
//         points: 4500,
//         avatar: Avatar,
//         color: 'border-[#FF3821]'
//     },
//     {
//         name: 'Jack',
//         points: 4550,
//         avatar: Avatar1,
//         color: 'border-[#FE8125]'
//     },
//     {
//         name: 'Rose',
//         points: 4225,
//         avatar: Avatar2,
//         color: 'border-[#E051CF]'
//     },
//     {
//         name: 'Stephen',
//         points: 1245,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     },
//     {
//         name: 'Thiru',
//         points: 4036,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     },
//     {
//         name: 'Rocky',
//         points: 4568,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     },
//     {
//         name: 'Virat',
//         points: 4036,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     },
//     {
//         name: 'Sunil',
//         points: 4568,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     },
//     {
//         name: 'Ronaldo',
//         points: 4036,
//         avatar: Avatar1,
//         color: 'border-[#A137DF]'
//     }
// ]
