import React, { useState } from "react";
import { toast } from "react-toastify";
import { registerWithEmailAndPassword } from "../../firebase";
import { addUserApi, GetUserEmail } from "../../services/services";
import { RegisterToast } from "../../utils/toastMessages";
import ArrowBack from "../../assets/images/arrowback.svg";
import Login from "../../assets/images/login.svg";
import Eye from "../../assets/images/eye.svg";
import Eye_Close from "../../assets/images/eye_close.svg";
import User from "../../assets/images/user.svg";
import Call from "../../assets/images/call.svg";
import Email from "../../assets/images/email.svg";
import { AGREED_LABEL, PRIVACY_POLICY, TERMSOFSERVICE, USER_LABELS } from "../../utils/contants";

const Register = (props) => {
  const [userRegisterDetail, setUserRegisterDetail] = useState({
    name: "",
    email: "",
    password: "",
    mobNo: "",
    confirmPass: "",
  });
  const [checked, setChecked] = useState(false);
  const [validNameErr, setValidNameErr] = useState(false);
  const [nameLenghErr, setNameLenghErr] = useState(false);
  const [validEmailErr, setValidEmailErr] = useState(false);
  const [emailExistErr, setEmailExistErr] = useState(false);
  const [validPasswordErr, setValidPasswordErr] = useState(false);
  const [validMobnoErr, setValidMobnoErr] = useState(false);
  const [mismatchErr, setMismatchErr] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserRegisterDetail({ ...userRegisterDetail, [name]: value });
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    switch (name) {
      case "name":
        let special = value.replace(/[^\w\s]/gi, "");
        let data = special.replace(/[^\S*$]/gi, "");
        setUserRegisterDetail({ ...userRegisterDetail, [name]: data });
        let newUser;
        try {
          newUser = await GetUserEmail({
            fieldName: "username",
            userValue: data,
          });
        } catch (err) {
          console.log(err, "err in get user email");
        }
        if (name === "name" && newUser?.username && value !== "") {
          setValidNameErr(true);
        } else {
          setValidNameErr(false);
        }
        if (data.length < 4) {
          setNameLenghErr(true);
        } else {
          setNameLenghErr(false);
        }
        break;
      case "email":
        let data1 = value.replace(/[^\S*$]/gi, "");
        setUserRegisterDetail({ ...userRegisterDetail, [name]: data1 });
        let newEmail;
        try {
          newEmail = await GetUserEmail({
            fieldName: "email",
            userValue: data1,
          });
        } catch (err) {
          console.log(err, "err in get user email");
        }
        if (name === "email" && !value.match(mailFormat) && value !== "") {
          setValidEmailErr(true);
        } else {
          setValidEmailErr(false);
        }
        if (name === "email" && newEmail?.email && value !== "") {
          setEmailExistErr(true);
        } else {
          setEmailExistErr(false);
        }
        break;
      case "password":
        if (
          userRegisterDetail.confirmPass === "" ||
          userRegisterDetail.confirmPass === value
        ) {
          setMismatchErr(false);
        } else {
          setMismatchErr(true);
        }
        if (name === "password" && value.length < 6 && value !== "") {
          setValidPasswordErr(true);
        } else {
          setValidPasswordErr(false);
        }
        break;
      case "mobNo":
        if (name === "mobNo" && value !== "") {
          setValidMobnoErr(true);
        } else {
          setValidMobnoErr(false);
        }
        break;
      case "confirmPass":
        if (userRegisterDetail.password !== value && value !== "") {
          setMismatchErr(true);
        } else {
          setMismatchErr(false);
        }
        break;
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleRegister = async () => {
    const userPayload = {
      username: userRegisterDetail.name,
      password: userRegisterDetail.password,
      confirmpassword: userRegisterDetail.confirmPass,
      mobilenumber: userRegisterDetail.mobNo || null, // Allow for empty phone number
      email: userRegisterDetail.email,
    };

    try {
      let userRes = await addUserApi(userPayload);
      if (userRes) {
        console.log("add user api success");
        if (userRes?.email && userRes?.password) {
          let payload = {
            email: userRes.email,
            password: userRes.password,
          };
          let res = await registerWithEmailAndPassword(payload);
          if (res) {
            const userData = {
              email: res.user.email,
              uid: res.user.uid,
            };
            toast.success(RegisterToast.register);
            props.logShow(true);
            props.registered(true);
          } else {
            toast.error(RegisterToast.error);
          }
        }
      }
    } catch (err) {
      console.log(err, "add user api error");
      toast.error(RegisterToast.error);
    }
  };

  const handleClear = () => {
    setUserRegisterDetail({
      email: "",
      password: "",
      name: "",
      confirmPass: "",
      mobNo: "",
    });
  };

  const isFormValid = () => {
    return (
      userRegisterDetail.email !== "" &&
      userRegisterDetail.password !== "" &&
      userRegisterDetail.name !== "" &&
      userRegisterDetail.confirmPass !== "" &&
      !validEmailErr &&
      !emailExistErr &&
      !validPasswordErr &&
      !mismatchErr &&
      !validNameErr &&
      !nameLenghErr &&
      checked
    );
  };

  return (
    <section className="flex bg-white rounded">
      <div className="w-[258px] min-h-[400px] h-[80%] bg-white rounded-lg px-5 py-7 sm:w-[360px]">
        <div className="flex justify-between items-center mb-2">
          <img
            src={ArrowBack}
            alt="arrowback"
            className="h-4 w-4 hover:cursor-pointer"
            onClick={() => props.logShow(true)}
          />
          <div className="flex w-[30%] justify-between">
            <img src={Login} alt="login" className="h-6 w-6" />
            <h1 className="text-xl text-bgMain font-[sfPro-bold]">
              {USER_LABELS.SINGUP}
            </h1>
          </div>
          <div className="w-[5%]"></div>
        </div>
        <div className="flex flex-col justify-around min-h-[380px] h-[66%]">
          {/* Name Input */}
          <div>
            <h3 className="font-[sfPro-bold] text-bgMain text-[15px]">
              {USER_LABELS.USERNAME}
            </h3>
            <div className="flex">
              <input
                type="text"
                className="w-full border-b-2 border-b-black focus:outline-none font-[sfPro-bold] text-bgMain text-[17px] inputbase pb-1"
                name="name"
                onChange={handleChange}
                value={userRegisterDetail.name}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img src={User} alt="user" className="h-3 w-4 mr-2" />
              </div>
            </div>
            {validNameErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.USERNAME_EXIST}
              </span>
            )}
            {nameLenghErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.USERNAME_VALIDATION}
              </span>
            )}
          </div>

          {/* Password Input */}
          <div>
            <h3 className="font-[sfPro-bold] text-bgMain text-[15px]">
              {USER_LABELS.PASSWORD}
            </h3>
            <div className="flex">
              <input
                type={passwordShow ? "text" : "password"}
                className="w-full border-b-2 border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain text-[17px] inputbase pb-1"
                name="password"
                onChange={handleChange}
                value={userRegisterDetail.password}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img
                  src={passwordShow ? Eye : Eye_Close}
                  alt="eye"
                  className="h-5 w-4 hover:cursor-pointer mr-2"
                  onClick={() => setPasswordShow(!passwordShow)}
                />
              </div>
            </div>
            {validPasswordErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.PASSWORD_VALIDATION}
              </span>
            )}
          </div>

          {/* Confirm Password Input */}
          <div>
            <h3 className="font-[sfPro-bold] text-bgMain text-[15px]">
              {USER_LABELS.CONFIRM_PASSWORD}
            </h3>
            <div className="flex">
              <input
                type={confirmPassShow ? "text" : "password"}
                className="w-full border-b-2 border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain text-[17px] inputbase pb-1"
                name="confirmPass"
                onChange={handleChange}
                value={userRegisterDetail.confirmPass}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img
                  src={confirmPassShow ? Eye : Eye_Close}
                  alt="eye"
                  className="h-5 w-4 hover:cursor-pointer mr-2"
                  onClick={() => setConfirmPassShow(!confirmPassShow)}
                />
              </div>
            </div>
            {mismatchErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.CONFIRM_PASSWORD_VALIDATION}
              </span>
            )}
          </div>

          {/* Email Input */}
          <div>
            <h3 className="font-[sfPro-bold] text-bgMain text-[15px]">
              {USER_LABELS.EMAIL_ID}
            </h3>
            <div className="flex">
              <input
                type="email"
                className="w-full border-b-2 border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain text-[17px] inputbase pb-1"
                name="email"
                onChange={handleChange}
                value={userRegisterDetail.email}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img src={Email} alt="email" className="h-3 w-4 mr-2" />
              </div>
            </div>
            {validEmailErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.EMAILI_ID_VALIDATION}
              </span>
            )}
            {emailExistErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.EMAILI_ID_EXIST}
              </span>
            )}
          </div>
          <div>
            <h3 className="font-[sfPro-bold] text-bgMain text-[15px]">
              {USER_LABELS.MOBILE_NUMBER} <span className="text-gray-500 text-sm">(Optional)</span>
            </h3>
            <div className="flex">
              <input
                type="text"
                className="w-full border-b-2 border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain text-[17px] inputbase pb-1"
                name="mobNo"
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="15"
                value={userRegisterDetail.mobNo}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img src={Call} alt="call" className="h-3.5 w-4 mr-2" />
              </div>
            </div>
          </div>
        </div>

        {/* Terms and Conditions Checkbox */}
        <div className="mt-4">
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              className="h-4 w-4 mr-2"
              id="terms"
              checked={checked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="terms" className="text-sm text-gray-600">
              {AGREED_LABEL}{' '}
              <a href="/terms-of-service" className="text-blue-600 hover:underline">
                {TERMSOFSERVICE.TITLE}
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" className="text-blue-600 hover:underline">
                {PRIVACY_POLICY.TITLE}
              </a>
            </label>
          </div>
        </div>

        {/* Register and Clear Buttons */}
        <div className="flex w-full justify-center mt-4">
          <div className="flex w-[86%] justify-between gap-3">
            <button
              className={`w-28 h-10 bg-bgGreen rounded-md text-white font-[sfPro-medium]
                ${isFormValid() ? "opacity-100 hover:cursor-pointer" : "opacity-50 hover:cursor-default"}`}
              onClick={isFormValid() ? handleRegister : () => {}}
            >
              {USER_LABELS.SINGUP}
            </button>
            <button
              className={`w-28 h-10 ${
                Object.values(userRegisterDetail).some(value => value !== "")
                  ? "bg-textBlack"
                  : "bg-textBlack opacity-50"
              } rounded-md text-white font-[sfPro-medium]`}
              disabled={!Object.values(userRegisterDetail).some(value => value !== "")}
              onClick={handleClear}
            >
              {USER_LABELS.CLEAR_BUTTON}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;