import { initializeApp } from "firebase/app";
import {
  getFirestore,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import dotenv from 'dotenv';
dotenv.config(); // Load environment variables
// let firebaseConfig;
// console.log(process.env.HOST_ENV,"-->ENVIRONMENT")
// if (process.env.HOST_ENV === 'development') {
//   firebaseConfig = firebaseDev
// } else {
//   firebaseConfig = firebaseProd
// }
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBMKXe_EyIITXxqwVykMlbMRZk-zAmrpO0",
//   authDomain: "money-burn.firebaseapp.com",
//   projectId: "money-burn",
//   storageBucket: "money-burn.appspot.com",
//   messagingSenderId: "1009754719684",
//   appId: "1:1009754719684:web:a73660b7db557ac2839091",
//   measurementId: "G-R5FSQT125R"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBgFy2upcapZr6VXbntgMOw9JLS7aUjCZg",
//   authDomain: "moneyburn---development.firebaseapp.com",
//   projectId: "moneyburn---development",
//   storageBucket: "moneyburn---development.appspot.com",
//   messagingSenderId: "187226700632",
//   appId: "1:187226700632:web:a8356f94f97fd7aea9832d"
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

const logInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result;
  }
  catch(err) {
    console.log(err, "err in google login");
  }
}

const logInWithFacebook = async () => {
  const facebookProvider = new FacebookAuthProvider();
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    return result;
  }catch(err) {
    console.log(err,"err in fb login")
  }
}

const registerWithEmailAndPassword = async (registeruserdata) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, registeruserdata.email, registeruserdata.password);
    // console.log(res, "firebase res")
    return res;
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async (loginuserdata) => {
  try {
    let result = await signInWithEmailAndPassword(auth, loginuserdata.email, loginuserdata.password);
    return result;
  } catch (err) {
    console.error(err);
  }
};


const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password Reset Link Sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = (callback = ()=>{}) => {
  signOut(auth).then(()=>{
    console.log("logout successfully");
    localStorage.clear()
    callback()
  }).catch(()=>{
    console.log("Error in Logout")
  });
};

export {
  auth,
  db,
  logInWithGoogle,
  logInWithFacebook,
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout
};