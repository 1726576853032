import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Register from "../register/Register";
import LogIn from "../log-in/LogIn";
import { GetUserCountry } from "../../services/services";
import { storeCountryRes } from "../../redux/reducers/userSlice";
import UseGeoLocation from "../../utils/useGeoLocation";
import BonFire from "../../assets/images/bonfire.svg";
import PlayRed from "../../assets/images/play_red.svg";
import LeaderBoard from "../../container/LeaderBoard/LeaderBoard";
import { BURN_LABEL, CORPORATE_RESPONSIBILITIES, HOME_PAGE_CONTENT, HOME_PAGE_SOCIAL_RESPONSIBILITY_CONTENT, MONEY_LABEL, NEGATIVITY_LABEL, PLAY_LABEL, READ_MORE_LABEL } from "../../utils/contants";

const Home = () => {
  const [isLoginShow, setLoginShow] = useState(false);
  const [isRegisterShow, setRegisterShow] = useState(false);
  const [isRegistered, setRegistered] = useState(false);
  const dispatch = useDispatch();
  const location = UseGeoLocation(); //geolocation component gets called
  const registerShow = (data) => {
    setRegisterShow(data);
    setLoginShow(!data);
  };
  const loginShow = (data) => {
    setLoginShow(data);
    setRegisterShow(!data);
  };
  const registerUpdate = (data) => {
    setRegistered(isRegistered === false ? data : !data);
  }
  const getCountry = async () => {
    try {
      let countryLattitude = location?.coordinates?.lat;
      let countryLongitude = location?.coordinates?.lng;
      if (countryLattitude && countryLongitude) {
        let country = await GetUserCountry(countryLattitude, countryLongitude);
        dispatch(storeCountryRes(country));
      }
    } catch (err) {
      console.log(err, "err in country res");
    }
  }

  useEffect(() => {
    try {
      getCountry();
    } catch (err) {
      console.log(err, "err in get country");
    }
  }, [location, isRegisterShow, isLoginShow]);

  return (
    <section>

      <div className="bg-bgMain bg-flames bg-cover bg-no-repeat overscroll-x-contain min-h-[600px] min-w-0  h-screen flex flex-col overflow-hidden sm:min-w-[920px] max-w-screen-2xl mx-auto">
        <div className="px-[10px] py-10 flex flex-col sm:px-12">
          <img src={BonFire} alt="bonfire" className="h-16 w-16" />
          <div className="flex text-[30px] text-textOrange font-['funkRocker-regular'] whitespace-pre gap-1 sm:gap-0">
            <div className="dynamic-txt text-[23px] sm:text-[50px]">{BURN_LABEL}</div>
            <ul className="dynamic-txt text-[23px] text-rd sm:text-[50px] sm:ml-4">
              <li><span>{MONEY_LABEL}</span></li>
              <li><span>{NEGATIVITY_LABEL}</span></li>
            </ul>
          </div>
          <p className="text-white text-[15px] mb-[10px] w-full sm:w-[60%] opacity-60 font-[raleWay-regular] text-balance">
            {HOME_PAGE_CONTENT}
          </p>
          <div class=" text-white mb-[20px] sm:w-[60%] rounded-lg shadow-lg opacity-60 font-[raleWay-regular] h-full flex items-end">
          <span>
            <p class="b-6 text-[15px]">
              {HOME_PAGE_SOCIAL_RESPONSIBILITY_CONTENT}
              <a href="/social-responsibility"
                className="text-blue-500 font-bold text-[15px] ml-[10px] bg-black w-[100px] hover:underline focus:outline-none p-1 rounded"
              >
                {READ_MORE_LABEL}
              </a>
            </p>


          </span>
        </div>
          <div className="flex justify-evenly items-center h-12 w-32 p-[5px] bg-[white] rounded-lg cursor-pointer mt-[15px] sm:mt-0"
            onClick={() => setLoginShow(true)}>
            <img
              src={PlayRed}
              alt="playred"
              className={`z-50 ${(isLoginShow || isRegisterShow) ? 'h-8 opacity-80' : 'h-6 opacity-100'}`}
            />
            <h1 className={`text-2xl h-8 text-[#e03757] font-[showGathic-regular] ${(isLoginShow || isRegisterShow) ? 'opacity-90' : 'opacity-100'}`}>{(isLoginShow || isRegisterShow) ? PLAY_LABEL : BURN_LABEL}</h1>
          </div>

        </div>
       

        {/* <div className="text-white h-full flex pl-[35px] pb-[35px] items-end text-[15px] w-full sm:w-[60%] opacity-60 font-[raleWay-regular] text-balance bottom-4">{CORPORATE_RESPONSIBILITIES}</div> */}
      </div>
      {isLoginShow && (
        <div className="absolute top-[17%] z-50 flex justify-center w-full">
          <LogIn regShow={registerShow} />
        </div>
      )}
      {isRegisterShow && (
        <div className="absolute top-[10%] flex justify-center w-full z-50">
          <Register logShow={loginShow} registered={registerUpdate} />
        </div>
      )}
      {isLoginShow && (
        <div
          className="opacity-60 fixed inset-0 z-30 bg-bgBlack"
        // onClick={() => setLoginShow(false)}
        ></div>
      )}
      {isRegisterShow && (
        <div
          className="opacity-60 fixed inset-0 z-30 bg-bgBlack"
        // onClick={() => setRegisterShow(false)}
        ></div>
      )}

    </section>
  );
};

export default Home;
