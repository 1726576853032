import { memo } from "react"
import BACK from "../../assets/images/backIcon.png";
import { BURN_LABEL, NEGATIVITY_LABEL, REFUND_POILCY, REFUND_POLICY_LABEL } from "../../utils/contants";

const RefundPolicy = () => {
    return (
        <section className="flex flex-col relative bg-[#452E26] min-h-screen gap-5 max-w-screen-2xl mx-auto">
            <button type="button" className="ml-1 mr-5 mt-2 fixed z-20" onClick={() => window.history.back()}>
            <img className="cursor-pointer" width={30} src={BACK} />
          </button>
            <div className="flex flex-col w-[75%] mx-auto items-center ">
            <span className="text-[5rem] text-[#FFA91A] flex justify-center font-[funkRocker-regular] sm:text-[12rem] sm:h-[35vh] 2xl:h-[15vh]">
            {BURN_LABEL}
          </span>
          <span className="text-[2rem] text-[#FFA91A] flex justify-end h-[10vh] font-[funkRocker-regular] sm:mt-[5vw] sm:w-[80%] sm:p-0 text-nowrap">
            {NEGATIVITY_LABEL}
          </span>
            </div>
            <div className="left-0 right-0 top-[200px] bottom-0 flex justify-center p-3">
                <div className="bg-bgBlack border-8 border-[#D9C3AF] shadow-3xl shadow-[#D9C3AF]/60 rounded-2xl relative h-full text-white font-mono p-5 w-[92%] sm:w-[80%]">
                    <h2 className="font-bold text-[2rem] text-center">{REFUND_POILCY.LABEL}</h2>
                    <div className="w-[92%] mx-auto">
                        <ul>
                            <li className="list-['◉'] pl-[30px] my-[10px]">{REFUND_POILCY.CONTENT1}</li>
                            <li className="list-['◉'] pl-[30px] my-[10px]">{REFUND_POILCY.CONTENT2}</li>
                            <li className="list-['◉'] pl-[30px] my-[10px]">{REFUND_POILCY.CONTENT3}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default memo(RefundPolicy);