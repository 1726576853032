import AvatarWhite from "../../assets/images/user1.svg"
const LeaderCard = ({ rank, lead }) => {
  return (
    <div
      //   key={rank}
      className={`flex-col mb-2`}
      style={{
        animationDelay: `${rank * 0.1}s`, // Add animation delay for each card
        // backgroundColor: rank%2===0 ? `rgba(255, 169, 26, 0.5)` : null,
        borderRadius: "7px",
      }}
    >
      <ul
        style={
          {
            // opacity: '0.8', borderRadius: "8%",
            //   backgroundImage: !isMore
            //     ? null
            //     : `linear-gradient(to bottom, #FFECB3, #FFE082, #FFD54F, #FFCA28)`,
          }
        }
        class="inline-flex"
      >
        <li
          className={`inline-flex gap-2 w-[18vw] border-l-2 rounded-t-md ${lead.color} rounded-b-md pl-3`}
        >
          {rank>=0?<span
            className={`${
              rank % 2 == 0 ? "text-white" : "text-white"
            } p-1 h-[30px] w-0 text-[12px] font-[800] font-fantasy`}
          >
            {rank + 1}
          </span>:null}
          <span className="rounded-[50%]">
            <img width={40} src={AvatarWhite}></img>
          </span>
          <span
            className={`flex-col inline-flex ${
              rank % 2 == 0 ? "text-white" : "text-white"
            } justify-center align-middle w-[8vw]`}
          >
            <label
              className={`text-[600] text-[14px] overflow-hidden overflow-ellipsis capitalize font-[sfPro-bold]`}
              title={lead.name}
            >
              {lead.name} (you)
            </label>
            <span className="text-[11px] font-[sfPro-bold] italic">
              <span>{lead.points.toLocaleString()} pts</span>
            </span>
          </span>
          {/* {rank < LEADER_BOARD_LIST_LENGTH && (
            <img
              className={`w-7 ${
                rank < LEADER_BOARD_LIST_LENGTH ? "ml-0" : "ml-20"
              }`}
              src={onGetBadges(rank)}
            ></img>
          )} */}
        </li>
      </ul>
    </div>
  );
};

export default LeaderCard;
