import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userSlice from "../redux/reducers/userSlice";

const persistConfig = {
    key: 'root',
    storage,
};

const customizedMiddleware = (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
};

const rootReducer = combineReducers({
    user: userSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: customizedMiddleware,

})

export const persistor = persistStore(store);

