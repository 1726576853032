import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { storeCountryDetails } from "../redux/reducers/userSlice";

const UseGeoLocation = () => {
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: {
            lat: '',
            lng: ''
        }
    });
    const dispatch = useDispatch();
    const onSuccess = (location) => {
        setLocation({
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng:  location.coords.longitude
            }
        });
    }

    const onError = (error) => {
        setLocation({
            loaded: true,
            error
        })
    }

    useEffect(()  =>{
        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Geolocation Not Supported"
            })
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    },[]);
    useEffect(()=>{
        dispatch(storeCountryDetails(location));
    },[location]);

    return location;
}

export default UseGeoLocation;