import React, { useCallback, useEffect, useState } from "react";
import Card from "../../components/card/Card";
import leaderBoardIcon from '../../assets/images/leaderboardIcon.svg'

import {
  BORDER_COLORS,
  LEADER_BOARD_LIST_LENGTH,
} from "../../utils/defaultVariables";
import LeaderCard from "../leaderCard";
import { useSelector } from "react-redux";
import { WS_URL } from "../../config";

const LeaderBoard = () => {
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const currentUserId = useSelector((x) => x.user.userId);
  const logUserData = useSelector((x) => x.user.logUserDetails);
  const [userDetails, setUserDetails] = useState(null);

  const onMakeLeaderBoardData = (burnData) => {
    let leaderArray = [];

    burnData.map((burn, index) => {
      let initObj = {};
      initObj.name = burn.userName;
      initObj.countryCode = burn?.geographyCountryType || "";
      initObj.points = burn.totalPoints;
      initObj.newPoints = 0;
      initObj.rank = "";
      initObj.color = getRandomColors();
      initObj.id = burn.user_id;
      leaderArray.push(initObj);
    });
    setLeaderBoardData(leaderArray);
  };
  const getRandomColors = () => {
    let color = BORDER_COLORS[Math.floor(Math.random() * BORDER_COLORS.length)];
    return color;
  };

  const leaderBoardPoint = useCallback((leaderBoard) => {
    // leaderBoard.map((burnUserDetail, index) => {
    //   console.log(currentUserId,burnUserDetail.id,"burnUserDetail.id")
    //   if (index >= 5 && burnUserDetail.id === currentUserId) {
    //     setUserDetails({ data: burnUserDetail, rank: index });
    //   }
    // });

    let exist = leaderBoard.findIndex((lead) => lead.id == currentUserId);
    if (exist >= 0) {
      if (exist >= 5) {
        setUserDetails({ data: leaderBoard[exist], rank: exist });
      }
    } else {
      let newObj = {
        name: logUserData?.name || "",
        countryCode: "",
        points: 0,
        newPoints: 0,
        rank: "",
        color: "",
        id: currentUserId,
      };
      setUserDetails({ data: newObj, rank: -1 });
    }
  }, []);

  const onHandleLeaderBoardOption = () => {
    if (leaderBoardData.length == 0) {
      return "No entries yet, Stay tuned!";
    } else if (leaderBoardData.length > 5) {
      return "More";
    }
  };

  useEffect(() => {
    const socket = new WebSocket(WS_URL);
    socket.onmessage = function (event) {
      let parsedData = JSON.parse(event.data);
      onMakeLeaderBoardData(parsedData);
    };
    socket.onopen = () => {
      socket.send("getUsers");
    };

    return () => {
      socket.close();
    };
  }, []);


  const customPopupUI = (screen = 'all') => {
    return (
    <>
   
      <div className="flex h-full justify-between">
        
        <div
          className={
            showCustomPopup
              ? `flex relative top-0 w-full left-[1%] md:left-[34%] md:w-[25%] min-h-screen flex-wrap justify-center items-center rounded-md bg-repeat-round shadow-sm`
              : `inline-flex flex-col right-[2%] top-[11%] gap-5 w-[21vw] fixed 2xl:relative 2xl:w-full`
          }
        >
          <h2 className={`flex justify-center w-full font-[sfPro-bold] text-white`}>
            Leader Board
          </h2>
          {/* {showCustomPopup && (
            <span
              className="cursor-pointer pl-1 pr-1 text-[20px] ml-[25%] flex text-right bg-white rounded-md hover:bg-gray-200"
              onClick={() => {
                setShowCustomPopup(false);
              }}
            >
              &#10005;
            </span>
          )} */}
          <div
            className={`inline-flex flex-col z-[999] overflow-y-auto ${showCustomPopup ? "h-[80vh]" : "h-fit rounded"
              } bg-[rgba(0,0,0,0.5)] overflow-hidden scrollbar-hide z-[100] gap-6 p-2`}
          >
            <Card
              isMore={showCustomPopup}
              data={leaderBoardData}
              screen = {screen == 'max-md'?true:false}
              arrayLength={
                showCustomPopup
                  ? leaderBoardData.length
                  : LEADER_BOARD_LIST_LENGTH
              }
              leaderBoardPoint={leaderBoardPoint}
            ></Card>
            {!showCustomPopup && (
              <a
                hidden={
                  leaderBoardData.length > 0 && leaderBoardData.length < 5
                }
                className={`${leaderBoardData.length > 5 && "underline"
                  } text-center text-[#FFA91A] text-[14px] cursor-pointer hover:text-purple-100`}
                onClick={() => {
                  setShowCustomPopup(true);
                }}
              >
                {onHandleLeaderBoardOption()}
              </a>
            )}
          </div>
          {userDetails &&
            userDetails.hasOwnProperty("rank") &&
            userDetails.hasOwnProperty("data") &&
            !showCustomPopup ? (
            <LeaderCard rank={userDetails.rank} lead={userDetails.data} />
          ) : null}
        </div>
        {showCustomPopup && (
          <span
            className="cursor-pointer pl-1 pr-1 text-[20px] flex justify-center items-center 
             w-[50px] h-[50px] fixed mt-[10px] right-[10%]
             bg-white rounded-md hover:bg-gray-200"
            onClick={() => {
              setShowCustomPopup(false);
            }}
          >
            X
          </span>
        )}
      </div></>
    )
  }

  return (

    <>
      <div
        className={
          `hidden md:block h-full ${showCustomPopup
            ? "fixed top-0 left-0 w-full min-h-screen bg-black/80 z-[100] flex justify-center "
            : null}`
        }
      >
        {customPopupUI()}
      </div>
      {/* <div class="hidden sm:block fixed right-[30px] top-[10px]">
      HELLO
    </div> */}
      <div className='hidden max-md:block'>
        <img src={leaderBoardIcon} onClick={() => { setShowCustomPopup(true) }} alt='leaderboard' title="Leader board" className="w-8 mt-[6px] fixed right-16 top-1 justify-end cursor-pointer bg-black opacity-75 rounded-sm z-20"></img>


        {showCustomPopup && <div
          className={
            "fixed top-0 left-0 w-full min-h-screen bg-black/80 z-[100] flex justify-center"
          }>
          {customPopupUI('max-sm')}
        </div>}


      </div>

    </>
  );
};
export default LeaderBoard;
