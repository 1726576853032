import { API_HOST_URL } from "../config";

export const getBurnDetails = async (userid) => {
    let res = await fetch(`${API_HOST_URL}/api/getuserburns/${userid}`);
    let result = res.json();
    return result;
}

export const addCustomerEmail = async (payload,userId) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(payload)
    }
    let userid = userId
    let res = await fetch(`${API_HOST_URL}/api/customer-support-email/${userid}`,requestOptions);
    let result = await res.json()
    return result
}

export const addBurnApi = async (data) => {
    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }
    let res = await fetch(`${API_HOST_URL}/api/burn`, requestOptions);
    let result = await res.json();
    return result;
}

export const addPaymentDetails = async (pay) => {
    const payload = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pay),
    }
    let res = await fetch(`${API_HOST_URL}/api/payments`, payload);
    let result = res.json();
    return result;
}

export const addUserApi = async (data) => {
    const options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }
    let res = await fetch(`${API_HOST_URL}/api/users`, options);
    let result = res.json();
    return result;
}

// export const GetUserApi = async () => {
//     let res = await fetch("http://43.205.3.97:5000/api/getusers");
//     return res.json();
// }

export const GetUserCountry = async (lat, lng) => {
    let res = await fetch(`https://secure.geonames.org/countryCodeJSON?lat=${lat}&lng=${lng}&type=JSON&username=david17`);
    return res.json();
}

export const getUser = async (userid) => {
    let result = await fetch(`${API_HOST_URL}/api/getusers/${userid}`);
    return result.json();
}

export const getUserOrderBurn = async (userid) => {
    let result = await fetch(`${API_HOST_URL}/api/getuserorders/${userid}`);

    return result.json();
}

export const getUserPayments = async () => {
    let result = await fetch(`${API_HOST_URL}/api/getuser-payments/`);

    return result.json();
}

export const GetUserEmail = async (data) => {
    const options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }
    let res = await fetch(`${API_HOST_URL}/api/getSpecificUser`, options);
    let result = res.json();
    return result;
}