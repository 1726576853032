import React, { memo, useEffect, useRef, useState } from "react";
import {
  COUNT_INCREASE_BY,
  LEADER_BOARD_LIST_LENGTH,
} from "../../utils/defaultVariables";
import chevron1 from "../../assets/images/chevron-1.png";
import chevron2 from "../../assets/images/chevron-2.png";
import chevron3 from "../../assets/images/chevron-3.png";
import chevron4 from "../../assets/images/chevron-4.png";
import chevron5 from "../../assets/images/chevron-5.png";
import AvatarBalck from "../../assets/images/user1.png";
import AvatarWhite from "../../assets/images/user1.svg";
import { useSelector } from "react-redux";

const Card = memo(function Card({
  data,
  arrayLength,
  isMore,
  leaderBoardPoint,
}) {
  const [cards, setCards] = useState([]); // State to manage cards
  const [duplicateCard, setDuplicateCard] = useState([]);
  const [radndomIndexId, setRandomIndexId] = useState(0);

  const [clonedDiffData, setClonedDiffData] = useState([]);
  const [newPoints, setNewPoints] = useState(0);
  const prevProps = useRef(null);
  const currentUserId = useSelector((x) => x.user.userId);
  let index = 0;

  useEffect(() => {
    // Compare previous and current props when data changes

    if ((prevProps.current !== null && prevProps.current !== undefined) || screen) {
      if(screen){
        prevProps.current = data
      }else{
        if (prevProps.current.length == 0) {
          prevProps.current = data;
        }
      }
      let prevPropsData = [...prevProps.current];
      let currentData = [...data];
      let diffData = arrayDifference(prevPropsData, currentData);
      if (diffData?.length > 0) {
        diffData.sort((a, b) => b.points - a.points);
        leaderBoardPoint(diffData);
        setRandomIndexId(diffData[0].id);
        setNewPoints(diffData[0].newPoints);
        setClonedDiffData([...diffData]);
        setCards(diffData);
      }
    }
    // Update prevProps for the next render cycle
    prevProps.current = data;
  }, [data]);

  useEffect(() => {
    // This effect will run whenever 'cards' state changes
    // Here, you can update 'randomIndexId' and 'newPoints' based on 'cards'
    if (clonedDiffData.length > 0) {
      setRandomIndexId(clonedDiffData[0].id);
      setNewPoints(clonedDiffData[0].newPoints);
    }
  }, [clonedDiffData]);

  function arrayDifference(array1, array2) {
    let prevClone = [...array1];
    let currentClone = [...array2];
    currentClone.map((cur) => {
      let exist = prevClone.findIndex((pre) => pre.id == cur.id);

      if (exist >= 0) {
        prevClone[exist].newPoints = cur.points - prevClone[exist].points;
      }else{
        let newObj = {...cur}
        newObj.newPoints = cur.points
        newObj.points = 0
        prevClone.push(newObj)
      }
    });
    return prevClone;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      loopUntilCount(newPoints, (indexData, randomId) => {
        setCards((prevCards) => {
          const updatedCards = prevCards.map((card, index) => ({
            ...card,
            points: randomId == card.id ? card.points + indexData : card.points,
          }));
          updatedCards.sort((a, b) => b.points - a.points);

          updatedCards.forEach((card, index) => {
            card.top = (index + 1) * 75;
          });

          return updatedCards;
        });
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [radndomIndexId, clonedDiffData]);

  const loopUntilCount = (count, callback) => {
    let interval = setInterval(() => {
      if (index < count) {
        callback(COUNT_INCREASE_BY, radndomIndexId);
        index++;
      } else {
        const randomObjectWithIndex =
          getRandomObjectWithNewPointsGreaterThanZero(clonedDiffData);
        if (randomObjectWithIndex !== null) {
          setRandomIndexId(randomObjectWithIndex.object.id);
          setNewPoints(randomObjectWithIndex.object.newPoints);
        }
        clearInterval(interval); // Stop the interval when reaching the count
      }
    }, 100);
  };

  function getRandomObjectWithNewPointsGreaterThanZero(datas) {
    if (datas.length > 0) {
      const randomIndex = Math.floor(Math.random() * datas.length);
      if (datas[randomIndex].newPoints > 0) {
        return {
          index: randomIndex,
          object: datas[randomIndex],
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const onGetBadges = (index) => {
    let badgeArray = [chevron1, chevron2, chevron3, chevron4, chevron5];
    return badgeArray[index];
  };

  const isEven = (number) => {
    return number % 2 === 0;
  };

  const renderingCards = () => {
    return (
      <>
        {cards?.length > 0 &&
          cards.map((lead, index) =>
            index < arrayLength ? (
              <div
                key={index}
                className={`flex-col ${currentUserId == lead.id? 'animate-blink border-[white] bg-bgGreen':(isMore || !isMore && isEven(index))?'bg-[#FFA91A]':null} `}

                style={{
                  animationDelay: `${index * 0.1}s`, // Add animation delay for each card
                  borderRadius: "7px",
                }}
              >
                <ul
                  style={{
                    // opacity: '0.8', borderRadius: "8%",
                    // backgroundColor: isMore?'#FFA91A':null
                  }}
                  class="inline-flex"
                >
                  <li
                    className={`inline-flex gap-2 w-full border-l-2 rounded-t-md ${lead.color} rounded-b-md pl-3`}
                  >
                    {/* {currentUserId == lead.id ?<span class="animate-blink w-[9px] h-[9px] rounded-full bg-[darkgreen] ml-[14px] relative mr-1"></span>:null} */}
                    <span
                      className={`${
                        ((isMore || (!isMore && isEven(index))) && currentUserId !== lead.id)
                          ? "text-black"
                          : "text-white"
                      } p-1 h-[30px] w-0 text-[12px] font-[800] font-fantasy`}
                    >
                      {index + 1}
                    </span>
                    
                    <span className="rounded-[50%]">
                      <img width={40} src={isMore && currentUserId !== lead.id? AvatarBalck: isEven(index) && currentUserId !== lead.id?AvatarBalck:AvatarWhite}></img>
                     </span>
                    
                    <span
                      className={`flex-col inline-flex ${
                        ((isMore || (!isMore && isEven(index))) && currentUserId !== lead.id)
                          ? "text-black"
                          : "text-white"
                      } justify-center align-middle ${isMore?'w-[19vw] md:w-[8vw]':'w-[9vw]'} m-[1px]`}
                    >
                      <span 
                      title={lead.name}
                      className="overflow-hidden overflow-ellipsis flex"
                      >
                        <label  
                        className={`text-[600] text-[14px] overflow-hidden overflow-ellipsis capitalize font-[sfPro-bold] whitespace-nowrap`}>
                           {lead.name}
                        </label> 
                        {currentUserId == lead.id ?<label className="text-[600] text-[14px]">  (You)</label>:null}                    
                      </span>
                      <span className="text-[11px] font-[sfPro-bold] italic">
                        <span>{lead.points.toLocaleString()} pts</span>
                      </span>
                    </span>
                    {index < LEADER_BOARD_LIST_LENGTH && (
                      <span className="flex justify-center">
                        <img
                        className={`w-7 ${
                          index < LEADER_BOARD_LIST_LENGTH ? "ml-0" : "ml-20"
                        }`}
                        src={onGetBadges(index)}
                      ></img>
                      </span>
                      
                    )}
                  </li>
                </ul>
              </div>
            ) : null
          )}
      </>
    );
  };

  return <>{renderingCards()}</>;
});

export default Card;
