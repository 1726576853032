// CustomerSupport.js
import React, { useState } from 'react';
import BACK from "../../assets/images/backIcon.png";
import { BURN_LABEL, CUSTOMER_SUPPORT, NEGATIVITY_LABEL } from '../../utils/contants';
import EMAIL_ICON from "../../assets/images/emailIcon.svg"
import PHONE_ICON from "../../assets/images/phoneIcon.svg"
import { useSelector } from 'react-redux';
import {addCustomerEmail } from '../../services/services';
import { toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';
function CustomerSupport() {
  let userId = useSelector((x) => x.user.userId)
const [formData,setFormData] =useState({
  name:'',
  email:'',
  message:'',
})

const [loading,setLoading] = useState(false)

const onInputChange =(e)=>{
  let name = e.target.name
  let value = e.target.value 
  setFormData({...formData,[name]:value})
}
  const handleSubmit = async (e)=>{
    e.preventDefault()
    setLoading(true)
    const result = await addCustomerEmail(formData,userId)
    if(result.statusCode == 200){
      setLoading(false)
      toast.success(result.message)
    }
  }
  return (
        <>
         {loading ? (
        <div className="absolute top-[25%] left-[48%]">
          <Loader />
        </div>
      ) : null}
        <section className="flex flex-col relative bg-[#452E26] min-h-screen gap-5 max-w-screen-2xl mx-auto">
            <button type="button" className="ml-1 mr-5 mt-2 fixed z-20" onClick={() => window.history.back()}>
            <img className="cursor-pointer" width={30} src={BACK} />
          </button>
            <div className="flex flex-col w-[75%] mx-auto items-center ">
            <span className="text-[5rem] text-[#FFA91A] flex justify-center font-[funkRocker-regular] sm:text-[12rem] sm:h-[35vh] 2xl:h-[15vh]">
            {BURN_LABEL}
          </span>
          <span className="text-[2rem] text-[#FFA91A] flex justify-end h-[10vh] font-[funkRocker-regular] sm:mt-[5vw] sm:w-[80%] sm:p-0 text-nowrap">
            {NEGATIVITY_LABEL}
          </span>
            </div>
            <div className="left-0 right-0 top-[200px] bottom-0 flex justify-center p-3">
                <div className="bg-bgBlack border-8 border-[#D9C3AF] shadow-3xl shadow-[#D9C3AF]/60 rounded-2xl relative h-full text-white font-mono pb-5 w-[92%] sm:w-[80%]">
                    <h2 className="font-bold text-[2rem] text-center p-5">{CUSTOMER_SUPPORT.TITLE}</h2>
                    <div className="w-[92%] mx-auto">
                    <section className="max-w-3xl mx-auto px-4">
          <h2 className="text-xl font-semibold mb-4">{CUSTOMER_SUPPORT.LIST1.TITLE}</h2>
          <p className="text-white">
           {CUSTOMER_SUPPORT.LIST1.DATA}
          </p>
        </section>
        <section className="max-w-3xl mx-auto px-4 mt-8">
          <h2 className="text-xl font-semibold mb-4">{CUSTOMER_SUPPORT.LIST2.TITLE}</h2>
          <ul className="space-y-4">
            <li className="text-white">
              <strong>{CUSTOMER_SUPPORT.LIST2.QUESTION}</strong>
              <p className="text-white">{CUSTOMER_SUPPORT.LIST2.DATA}</p>
            </li>
            {/* <li className="text-gray-800">
              <strong>How can I track my order?</strong>
              <p className="text-gray-700">You can track your order by logging into your account.</p>
            </li>
            <li className="text-gray-800">
              <strong>What is your return policy?</strong>
              <p className="text-gray-700">Our return policy allows returns within 30 days of purchase.</p>
            </li> */}
          </ul>
        </section>
        <section className="max-w-3xl mx-auto px-4 mt-8">
          <h2 className="text-xl font-semibold mb-4">{CUSTOMER_SUPPORT.LIST3.TITLE}</h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-white">{CUSTOMER_SUPPORT.LIST3.LABEL1}</label>
              <input type="text" id="name" onChange={onInputChange} value={formData.name} name="name" className="w-full border text-black border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" required />
            </div>
            <div>
              <label htmlFor="email" className="block text-white">{CUSTOMER_SUPPORT.LIST3.LABEL2}</label>
              <input type="email" id="email" onChange={onInputChange} value={formData.mail} name="email" className="w-full border text-black border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" required />
            </div>
            <div>
              <label htmlFor="message" className="block text-white">{CUSTOMER_SUPPORT.LIST3.LABEL3}</label>
              <textarea id="message" onChange={onInputChange} value={formData.message} name="message" rows="4" className="w-full border text-black border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" required></textarea>
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">{CUSTOMER_SUPPORT.LIST3.BUTTON_LABEL}</button>
          </form>
          <div className="mt-8 flex-row justify-center sm:flex  gap-2">
            <div className="flex items-center mr-8">
              <img src={PHONE_ICON} alt="Phone" className="w-6 h-6 mr-2" />
              <span className="text-white">{CUSTOMER_SUPPORT.PHONE.VALUE}</span>
            </div>
            <div className="flex items-center">
              <img src={EMAIL_ICON} alt="Email" className="w-6 h-6 mr-2" />
              <span className="text-white">{CUSTOMER_SUPPORT.EMAIL.VALUE}</span>
            </div>
          </div>
        </section>
                    </div>
                </div>
            </div>
        </section>
        </>
    )


    // <div className="bg-gray-100 min-h-screen">
      {/* <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-center">
          <h1 className="text-2xl font-bold text-gray-800 ">Customer Support</h1>
        </div>
      </header> */}

    //   <footer className="bg-white mt-8 py-4">
    //     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-gray-600 text-center">
    //       <p>&copy; 2024 Your Company. All rights reserved.</p>
    //     </div>
    //   </footer>
    // </div>
  
}

export default CustomerSupport;
