import React from "react";
import { ABOUT_US, ABOUT_US_LABEL, BURN_LABEL, NEGATIVITY_LABEL } from "../../utils/contants";
import BACK from "../../assets/images/backIcon.png";

const AboutUs = () => {

    return (
        <section className="bg-[#452E26] flex-col justify-center align-middle min-h-screen max-w-screen-2xl mx-auto">
            {/* <label>About Us</label> */}
            <button type="button" className="ml-1 mr-5 mt-2 fixed z-20" onClick={() =>  window.history.back()}>
            <img className="cursor-pointer" width={30} src={BACK} />
          </button>
            <span className="text-[5rem] text-[#FFA91A] flex justify-center font-[funkRocker-regular] sm:text-[12rem] sm:h-[34vh] 2xl:h-[15vh]">
            {BURN_LABEL}
          </span>
          <span className="text-[2rem] text-[#FFA91A] flex justify-end h-[10vh] font-[funkRocker-regular] pr-5 sm:w-[80%] sm:p-0 sm:mt-[5vw]">
            {NEGATIVITY_LABEL}
          </span>
            {/* <div className="self-center rounded-md w-full flex justify-center p-5">
                <div className="font-mono ml-0 text-white bg-bgBlack border-8 border-[#D9C3AF] shadow-3xl h-full w-[80%] shadow-[#D9C3AF]/60 rounded-2xl relative p-5">
                    <label className="w-full flex justify-center font-[700] text-[20px] ">About Us</label>
                    <span className="font-mono text-[13px]">
                    {ABOUT_US}
                         </span>
                </div>
            </div> */}

            <div className="left-0 right-0 top-[200px] bottom-0 flex justify-center mt-7">
                <div className="bg-bgBlack border-8 border-[#D9C3AF] shadow-3xl shadow-[#D9C3AF]/60 rounded-2xl relative h-full text-white font-mono w-[92%] p-5 sm:w-[80%]">
                    <h2 className="font-bold text-[2rem] text-center">{ABOUT_US.LABEL}</h2>
                    <div className="mx-auto p-2 sm:p-5 inline-flex flex-col gap-3">
                      {Object.keys(ABOUT_US).map((content,index)=>(
                        ABOUT_US[content]!=='About us' && <p>{ABOUT_US[content]}</p>
                      ))}
                    </div>
                </div>
            </div>
            
            {/* <div class="relative overflow-hidden shadow-md rounded-lg h-[50vh] w-[70%] self-center">
  <div class="absolute inset-0 bg-gray-200 transform origin-top-right -skew-y-6"></div>
  <div class="p-8">
    <h2 class="text-xl font-semibold mb-2">Bending Card</h2>
    <p class="text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in metus at quam convallis commodo.</p>
  </div>
</div> */}
        </section>
    )

}

export default AboutUs