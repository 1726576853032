import React from "react";
import RenderDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app";
import { persistor, store } from "./redux/store";
import './index.css';

const root = RenderDOM.createRoot(document.getElementById('app'));

root.render(
    <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
    </Provider>
);