import React from "react";
import { logout } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function LogOut() {
  const userState = useSelector((state) => state.user);

  const onSuccess = () => {
    window.location.href = "/";
  };

  if (userState.isUserAuthenticated) {
    return (
      <div className="w-full flex justify-center max-w-screen-2xl mx-auto">
         <button
        className="z-10 top-0 max-w-screen-2xl mx-auto"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "5px",
          position: "fixed",
          width:'100%',
        }}
      >
        <img
          src={require("../../assets/images/log-out.png")}
          alt="log-out"
          width="40px"
          height="25px"
          onClick={() => logout(onSuccess())}
        />
      </button>
      </div>
     
    );
  } else return <></>;
}
